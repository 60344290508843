import React from "react";

import "./programme.scss";

function Programme() {
  return (
    <div className="Programme">
      <img className="grosse" src="/charles.png" width="100%"></img>
      <h1 className="maClasse">DECENTRALIZATION (2024)</h1>
      <p>
        - do away with centralization of the power and support decentralization
        <br />- create a tips bot for $JIT
        <br />- solve the trilema
        <br />- lower SP98 gasoline prices and eth gaz
        <br />- be scalable as fuck
        <br />- safety is important, so be safe
        <br />- be scalable as fuck again
        <br />- brexit - Leave the EU
        <br />- Pump $RADIX
        <br />- Open a school to learn 'How to be JIT'
      </p>
      <h1 className="maClasse">SECURITY (2024)</h1>
      <p>
        <br />- Lock the LP and the rest of the supply
        <br />- Send the rest of the supply to Dan
        <br />- Lock Dan
        <br />- Danlock
        <br />- bring peace to the world with $JIT airdrops
        <br />- HUG your neighbor
        <br />- give a gun to holders for every 2 millions $JIT hold to be able
        to shoot people who want to rob us
        <br />- HUG the wife of your neighbor
        <br />
        - raising the alcohol limit for driving
        <br />
      </p>
      <h1 className="maClasse">TECHNOLOGY (2034)</h1>
      <p>
        - update the concensus with incomprehensible stuff like
        <br />
        <img className="monimage" width="100%" src="/con.png"></img>
        <br />
        - be more sharded than a soldier stepping on a mine
        <br />
        - Achieving Unlimited dApp Throughput
        <br />
        - Be listed on coingecko
        <br />
        - Upload a good icon
        <br />- Redevelop the website
        <br />- Create something to control the weather to stop the fucking rain
        in england
        <br />
        - Binance listing
        <br />- Give access to the DeFi to our grandparent so they can swap
        shitcoin on Ociswap
      </p>
      <h1 className="maClasse">XI'AN</h1>As Dan takes too long to release Xi'an
      we decided to do it, just in time for the bull run.
      <h3 className="maClasse">how we'll proceed</h3>
      <p>
        <br />
        - stole the code to Dan
        <br />
        - yarn install
        <br />
        - yarn start
        <br />
        - Open http://localhost:8080
        <br />
        - do 9000 swaps / sec
        <br />
        - Be rich
        <br />
      </p>
      <h3 className="maClasse">
        and many more coming{" "}
        <span style={{ textDecoration: "line-through" }}>soon</span> just in
        time
      </h3>
    </div>
  );
}

export default Programme;
