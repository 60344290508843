import React from "react";

import "./partenaire.scss";

function Partenaire() {
  return (
    <div className="partenaire">
      <h1>Our investors and partners</h1>
      <table className="montableau mobile-hide">
        <body>
          <tr>
            <td>
              <img className="monimage" src="/binance.png"></img>
            </td>
            <td>
              <img className="monimage" src="/radix.png"></img>
            </td>
            <td>
              <img className="monimage" src="/binch.jpeg"></img>
            </td>
          </tr>
          <tr>
            <td>
              <img className="monimage" src="/black.jpeg"></img>
            </td>
            <td>
              <img className="monimage" src="/batchata.jpg"></img>
            </td>
            <td>
              <img className="monimage" src="/elon.jpeg"></img>
            </td>
          </tr>
          <tr>
            <td>
              <img className="monimage" src="/manchester.jpeg"></img>
            </td>
            <td>
              <img className="monimage" src="/coinbase.jpeg"></img>
            </td>
            <td>
              <img className="monimage" src="/johndeere.jfif"></img>
            </td>
          </tr>
          <tr>
            <td>
              <img className="monimage" src="/dart.jpeg"></img>
            </td>
            <td>
              <img className="monimage" src="/vit.jpeg"></img>
            </td>
            <td>
              <img className="monimage" src="/kucoin.png"></img>
            </td>
          </tr>
          <tr>
            <td>
              <img className="monimage" src="/celine.jpeg"></img>
            </td>
            <td>
              <img className="monimage" src="/lambo.jpeg"></img>
            </td>
            <td>
              <img className="monimage" src="/apple.png"></img>
            </td>
          </tr>
        </body>
      </table>
      <ul className="montableau-mobile" style={{ padding: 0 }}>
        <li>
          <img className="monimage" src="/binance.png"></img>
        </li>
        <li>
          <img className="monimage" src="/radix.png"></img>
        </li>
        <li>
          <img className="monimage" src="/binch.jpeg"></img>
        </li>

        <li>
          <img className="monimage" src="/black.jpeg"></img>
        </li>
        <li>
          <img className="monimage" src="/batchata.jpg"></img>
        </li>
        <li>
          <img className="monimage" src="/elon.jpeg"></img>
        </li>

        <li>
          <img className="monimage" src="/manchester.jpeg"></img>
        </li>
        <li>
          <img className="monimage" src="/coinbase.jpeg"></img>
        </li>
        <li>
          <img className="monimage" src="/johndeere.jfif"></img>
        </li>

        <li>
          <img className="monimage" src="/dart.jpeg"></img>
        </li>
        <li>
          <img className="monimage" src="/vit.jpeg"></img>
        </li>
        <li>
          <img className="monimage" src="/kucoin.png"></img>
        </li>

        <li>
          <img className="monimage" src="/celine.jpeg"></img>
        </li>
        <li>
          <img className="monimage" src="/lambo.jpeg"></img>
        </li>
        <li>
          <img className="monimage" src="/apple.png"></img>
        </li>
      </ul>
    </div>
  );
}

export default Partenaire;
