import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GraphQLClient, ClientContext } from "graphql-hooks";
import ReactGA from "react-ga";
import { RdtProvider } from "./RdtProvider";
import {
  DataRequestBuilder,
  RadixDappToolkit,
  RadixNetwork,
} from "@radixdlt/radix-dapp-toolkit";

ReactGA.initialize("UA-163030135-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const client = new GraphQLClient({
  url: "https://graphql.datocms.com/",
  headers: {
    Authorization: "bd1012476def03dcaf620a7474edea",
  },
});

const rdt = RadixDappToolkit({
  dAppDefinitionAddress:
    "account_rdx12yr2ny2xcyqhwx35sl8nds4wngay0tltpc8mhq8wgh2paf4yeqg8y4",
  networkId: RadixNetwork.Mainnet,
  applicationName: "Radix Web3 dApp",
  applicationVersion: "1.0.0",
});
rdt.walletApi.setRequestData(
  DataRequestBuilder.persona(),
  DataRequestBuilder.accounts().exactly(1)
);

root.render(
  <React.StrictMode>
    <ClientContext.Provider value={client}>
      <RdtProvider value={rdt}>
        <App />
      </RdtProvider>
    </ClientContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
