import { useEffect, useState } from "react";

import { useRdt } from "./useRdt";
import { WalletDataState } from "@radixdlt/radix-dapp-toolkit";

export const useRdtState = () => {
  const rdt = useRdt();
  const [state, setState] = useState<WalletDataState>();

  useEffect(() => {
    const subscription = rdt.walletApi.walletData$.subscribe((state) => {
      setState(state);
      console.log(state);
    });

    return () => {
      subscription.unsubscribe();
    };
  });

  return state;
};
