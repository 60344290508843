import React from "react";

import "./getFLash.scss";

function GetFLash() {
  return (
    <div className="plugins">
      <div className="plugins-wrapper">
        <div>
          <span className="red">Without the flash plug-in,</span>
          <span className="yellow-color">you're missing half the fun!</span>
          <span className="white-color">Click here to get flash!</span>
          <img src="/get-flash.gif" alt="get flash logo" />
        </div>

        <div className="warning-message">
          Our UX designer is on holidays so please pay no attention to the
          design, will be fixed with Xi'an
        </div>
      </div>
    </div>
  );
}

export default GetFLash;
