import { useQuery } from "graphql-hooks";
import React, { useEffect, useState } from "react";
import "./idee.scss";
import { GatewayApiClient } from "@radixdlt/babylon-gateway-api-sdk";
import { useRdtState } from "../../hooks/useRdtState";
import BigNumber from "bignumber.js";
const { SiteClient } = require("datocms-client");

const IDEAS_QUERY = `query Idea {
  allIdeas{
    description
    name
  }
}`;

export const shrinkAddress = (address: string) => {
  if (address.length < 18) {
    return address;
  }
  return (
    address?.substring(0, 18) + "..." + address?.substring(address.length - 4)
  );
};

function Idee() {
  const client = new SiteClient("bd1012476def03dcaf620a7474edea");
  const [address, setAddress] = useState("");
  const [isValid, setIsValid] = useState(false);
  const gatewayApi = GatewayApiClient.initialize({
    basePath: "https://mainnet.radixdlt.com",
    applicationName: "Just in time",
  });
  const { state } = gatewayApi;
  const rdtState = useRdtState();

  useEffect(() => {
    if (address) {
      state.getEntityDetailsVaultAggregated(address).then((data) => {
        const tokens = data.fungible_resources.items;
        const jitToken = tokens.find(
          (token) =>
            token.resource_address ===
            "resource_rdx1tklqwvhfsm968vpnk03nn9g7kqp28h5du4x7mxnhjtqvq5jy97864s"
        );
        if (jitToken) {
          const isValid = jitToken.vaults.items.some((vault) =>
            BigNumber(vault.amount || 0).isGreaterThan(BigNumber(10000000))
          );
          setIsValid(isValid);
        }
      });
    } else setIsValid(false);
  }, [address, state]);

  useEffect(() => {
    if (rdtState?.accounts) {
      const account = rdtState?.accounts[0];
      if (account) {
        setAddress(account.address);
        setNom(account.address);
      } else {
        setNom("");
        setAddress("");
      }
    }
  }, [rdtState]);

  const { loading, error, data } = useQuery(IDEAS_QUERY, {});
  const [idea, setIdea] = useState("");
  const [nom, setNom] = useState("");
  const [errorrr, setError] = useState("");

  const submit = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    const error =
      !nom ||
      !idea ||
      (nom && idea && idea.length < 10) ||
      (nom && idea && idea.length > 600);

    if (error) {
      console.log(error);
      setError(
        "error system please fill all inputs or enter an idea with at least 10 character"
      );
      return;
    }

    await client.items.create({
      itemType: "923120", // model ID
      name: nom,
      description: idea,
    });
    setError("");
    alert("enregistré");
    //@ts-ignore
    window.location.reload();
  };

  if (loading) return <p>"Loading..."</p>;
  if (error) return <p>Something Bad Happened</p>;
  const ideas = data.allIdeas;

  return (
    <div className="Idee">
      <h1>
        because we're a decentralized protocol YOU have the power to change
        things, tell us what you'd like to see in the roadmap
      </h1>

      <h3>
        <img width="100" src="idea.gif" />{" "}
      </h3>
      {isValid ? (
        <form noValidate onSubmit={(e) => submit(e)}>
          <label>you name:</label>
          <input type="text" id="fname" disabled name="fname" value={nom} />
          <br></br>
          <label>your idea:</label>
          <textarea
            rows={8}
            id="lname"
            name="lname"
            onChange={(e) => setIdea(e.target.value)}
            value={idea}
          />
          <br></br>
          {errorrr && <p style={{ color: "red" }}>{errorrr}</p>}
          <input type="submit" value="send" />
        </form>
      ) : (
        <div style={{ width: "300px", margin: "auto" }}>
          {!address ? (
            <p>
              You must connect your wallet and hold at least 10 millions $JIT to
              be able to post ideas
            </p>
          ) : (
            <p>
              Bro, you dont have enought $JIT in your wallet, you need at least
              10 millions $JIT to access this feature
            </p>
          )}
        </div>
      )}
      <br></br>
      <h2>idea list</h2>
      <div style={{ padding: "10px" }}>
        <table
          style={{
            width: "100%",
            marginBottom: "100px",
            backgroundColor: "#98c4d4",
          }}
        >
          <tr
            style={{
              color: "white",
              backgroundColor: "#00445c",
            }}
          >
            <td>idea</td>
            <td>author</td>
          </tr>

          {ideas.map((idea: any) => {
            return (
              <tr>
                <td>{idea.description}</td>
                <td>
                  {" "}
                  by : <a href="/idee">{shrinkAddress(idea.name)}</a>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
}

export default Idee;
