import React from "react";

import "./inscription.scss";

function Inscription() {
  return (
    <div className="OnestQui">
      <h3>you have just entered the airdrop zone</h3>
      <p>it's not too late to take part in our airdrops,</p>
      <h3>why airdrop ?</h3>
      <p>
        because we want to convert as many people as possible to our lifestyle,
        which means sharing our resources.{" "}
      </p>
      <h3>how to be eligible airdrop ?</h3>
      <ul style={{ width: "100%", maxWidth: "400px", margin: "auto" }}>
        <li>Not be late</li>
        <li>Not be early</li>
        <li>Be just in time</li>
        <li>
          Join us on telegram :{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://t.me/justintimeradix"
          >
            Be $JIT
          </a>
        </li>
        <li>Follow us on our twitter that doesn't exist</li>
        <li>POST memes on our telegram</li>
      </ul>

      <h3>exemple</h3>
      <img src="/tele.png" style={{ width: "100%", margin: "auto" }} />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Inscription;
