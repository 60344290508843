import React from "react";

import "./post.scss";

interface Props {
  img: string;
  title: string;
  description: string;
  url: string;
}
function Post(props: Props) {
  return (
    <div
      className="post"
      style={{
        maxWidth: "300px",
        width: "100%",
        marginBottom: "10px",
        border: "1px dashed black",
        padding: "12px",
      }}
    >
      <img
        style={{ maxWidth: "300px" }}
        className="banner"
        src={props.img}
      ></img>
      <div className="info">
        <h3 className="title">{props.title}</h3>

        <p className="description">{props.description}</p>
        <a href={props.url}>More details</a>
      </div>
    </div>
  );
}

export default Post;
