import React, { useState } from "react";

import "./pubContainer.scss";

interface Props {
  children: any;
}

function PubContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(true);

  if (!isDisplay) {
    return null;
  }
  return (
    <div className="PubContainer">
      <button
        className="Button"
        onClick={() => {
          setIsDisplay(false);
        }}
      >
        X
      </button>
      {props.children}
    </div>
  );
}

export default PubContainer;
