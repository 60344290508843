import React from "react";

import "./annonce.scss";

interface Props {
  img: string;
  title: string;
  description: string;
  url: string;
  price: number;
}
function Annonce(props: Props) {
  return (
    <div className="annonce" style={{ width: "100%" }}>
      <a href={props.url}>
        <img className="banner" src={props.img}></img>
        <div className="info">
          <h3 className="title">{props.title}</h3>
          <span className="price">{props.price} $JIT</span>
          <p className="description">{props.description}</p>
        </div>
      </a>
    </div>
  );
}

export default Annonce;
