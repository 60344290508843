import React from "react";

import "./pubGrosseSanglier.scss";
import Slider from "react-slick";
import PubContainer from "../pubContainer";

function PubGrosseSanglier() {
  const settings = {
    infinite: true,
    autoplaySpeed: 2000,

    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <PubContainer>
      <div className="PubGrosseSanglier">
        <div className="grosse">
          <Slider {...settings}>
            <img className="grosse" src="/dan.jpg"></img>
            <img className="grosse" src="/piers.png"></img>
            <img className="grosse" src="/adam.jpg"></img>
            <img className="grosse" src="/jacob.png"></img>
            <img className="grosse" src="/grosse.png"></img>
          </Slider>
        </div>

        <div className="Title">
          <p>
            Send <span style={{ color: "red", fontSize: "24px" }}>$JIT</span> to{" "}
            <span
              style={{ color: "green", fontSize: "24px", fontWeight: "bold" }}
            >
              8332
            </span>
          </p>
          <img src="/phone.png"></img>
        </div>

        <p style={{ fontSize: "12px" }}>
          and meet the hottest guys in your region
        </p>
      </div>
    </PubContainer>
  );
}

export default PubGrosseSanglier;
