import React from "react";

import "./pubLeft.scss";
import Slider from "react-slick";
import PubContainer from "../pubContainer";
import PubGrosseSanglier from "../pubGrosseSanglier/pubGrosseSanglier";
import BlackConnect from "../blackConnect/blackConnect";
import PussyFinder from "../pussyFinder/pussyFinder";

function PubLeft() {
  const settings = {
    infinite: true,
    autoplaySpeed: 1800,

    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <PubContainer>
      <div className="PubLeft">
        <a href="http://shihtzu.free.fr/index.htm" target="_blank">
          <div className="bichons">
            <Slider {...settings}>
              <img className="bichon" src="/mini.png"></img>
              <img className="bichon" src="/caniche.jpg"></img>
              <img className="bichon" src="/raccoon.png"></img>
              <img className="bichon" src="/bichon.jpg"></img>
              <img className="bichon" src="/bichonfrise.jpg"></img>
              <img className="bichon" src="/shihtzu.jpg"></img>
              <img className="bichon" src="/bichonhavanais.jpg"></img>
            </Slider>
          </div>
        </a>
      </div>
      <div className="PubLeft">
        <PubGrosseSanglier />
        <BlackConnect />
        <PussyFinder />
      </div>
    </PubContainer>
  );
}

export default PubLeft;
