import React from "react";
import "./blackConnect.scss";
import PubContainer from "../pubContainer";

function BlackConnect() {
  return (
    <PubContainer>
      <img src="/early.jpg" style={{ width: "270px" }} className="grosse"></img>
    </PubContainer>
  );
}

export default BlackConnect;
