import React from "react";
import { Link } from "react-router-dom";

import "./menu.scss";

function Menu() {
  return (
    <div className="Menu">
      <p className="title">Menu</p>
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/whoweare.html">Who we are</a>
        </li>
        <li>
          <a href="/airdrops.html">Airdrops</a>
        </li>
        <li>
          <a href="/roadmap.html">Roadmap</a>
        </li>
        <li>
          <a href="/roadmap.html">Roadmap again</a>
        </li>

        <li>
          <a href="/marketplace.html">Market place</a>
        </li>
        <li>
          <a href="/ideas.html">DAO</a>
        </li>
        <li>
          <a href="/blog.html">Blog</a>
        </li>
        <li>
          <a href="/investors.html">Partners / Investors</a>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
