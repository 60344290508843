import React from "react";

import "./blog.scss";
import Post from "../../components/post/post";

interface BlogPost {
  img: string;
  title: string;
  description: string;
  url: string;
}

const posts: BlogPost[] = [
  {
    img: "/burned.png",
    title: "Why we burned 44% of the supply ?",
    description: "Because",
    url: "/blog/danlock.html",
  },
];

function Blog() {
  return (
    <div className="partenaire">
      <h1>News and Insights from the $JIT Team</h1>

      <h3>Check our latest news</h3>
      <div style={{ padding: "16px" }}>
        {posts.map((post) => {
          return (
            <Post
              img={post.img}
              title={post.title}
              description={post.description}
              url={post.url}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Blog;
