import React from "react";

import "./footer.scss";

function Footer() {
  return (
    <div className="Footer">
      <div style={{ display: "flex" }}>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
        <img className="sanglier" src="/sanglier.gif"></img>
      </div>
    </div>
  );
}

export default Footer;
