import React from "react";

function Danlock() {
  return (
    <div className="partenaire">
      <h1>Danlock</h1>
      <img src="/burned.png" width="100%" height="300px"></img>
      <h1>What's Danlock ?</h1>
      <p>
        Dan, our Technogodix, is the all-powerful creator behind $JIT, even if
        he didn't directly create the project, the idea came from him.
      </p>
      <img src="/proof.png" width="300px" style={{ margin: "auto" }}></img>
      <p>
        As you know, a project with such great ambitions has to be secure and
        transparent with its fans. This is why, after adding 44% of the supply
        to the liquidity pools (which will be locked with Radlock) we Danlocked
        / burned almost all the rest of the supply .
      </p>
      <p>
        A small part of the remaining supply (10B tokens or 10%) is kept as
        project treasury in order to animate the community, organize contests,
        pay developments, ...) Another tiny part of this supply (2B tokens or
        2%) is reserved for the project founder (Charlie).
      </p>
      <p>
        {" "}
        All the rest of the supply (44B tokens or 44% of the supply) has been
        sent to the Dan address{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://dashboard.radixdlt.com/account/account_rdx16y6p6aedt7xktl8gthq05mm85nh3gl5ztrq076r2v7rjk83f9gqmw9"
        >
          {" "}
          here{" "}
        </a>
      </p>
      <p>
        {" "}
        Transaction id{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://dashboard.radixdlt.com/transaction/txid_rdx1vmy65yaqfhdsts2x3zu4p9sda2vje824tp5k78rjdew7ummu88nq7h2xct/summary"
        >
          {" "}
          here{" "}
        </a>
      </p>
      <h1>How do we know that this is the Dan Address ?</h1>
      <p>
        Dan received a beautiful and rare one @AbandonedScorps for the new year,
        the number #3809 to be exact !
      </p>{" "}
      <img src="/tweet.png" width="250px" height="300px"></img>
      <p>
        You can verify that from on the official twitter account of Dan{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/fuserleer/status/1741542948318171314"
        >
          {" "}
          here{" "}
        </a>
      </p>{" "}
      <p>
        And the transaction{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://dashboard.radixdlt.com/transaction/txid_rdx16sgmj84fsxyv88ea2djmvwjpkv7k7ckyum7pkzspzl4wfpx20clq9rz6cp/summary"
        >
          {" "}
          here{" "}
        </a>
      </p>{" "}
      <p>
        And you can also check that the address where we sent the $JIT supply is
        also holding the @AbandonedScorps #3809 !
      </p>{" "}
      <h1>Why sent the supply to Dan ?</h1>
      <p>
        Like vitalik for $SHIBA, Dan is the god of $JIT ! He said multiple times
        that he'll not be involved in such project, so he will never touch these
        tokens. He's our Danlock and number one holder.
      </p>{" "}
      <p>
        Its a way to proof our honesty and we want to be as transparent as
        possible with our community. It's why we'll also lock the LP using
        Radlock or send it to the burn address (Need to be discussed with the
        community)
      </p>{" "}
    </div>
  );
}

export default Danlock;
