import React from "react";

import "./onestqui.scss";

function OnestQui() {
  return (
    <div className="OnestQui">
      <h3>who we are</h3>
      <p>
        we are the people who arrive on time, at the right time, in the right
        place. It's why we are over-invested on Radix
      </p>
      <h3>what do we want to do</h3>
      <p>
        $JIT is not just a simple meme token, it's a way of living, thinking and
        reflecting. We want share our life style with all.
      </p>
      <h3>why are you we the best and only solution for world peace</h3>
      <p>
        because its always to late to be early and to early to be late, but
        never to late to be just in time
      </p>
      <h3>are you married?</h3>
      <p>
        no, I want to stay that way to concentrate fully on the development of
        the project. Dan has often told us that his wife and children take up
        too much of his time, and I don't want to make the same mistake.
      </p>
      <h3>why this website is horrible ?</h3>
      <p>
        because we prefer to spend the budget on real things that help the world
        and the development of our project, like a Nissan GTR for the founder.
      </p>
      <h3></h3>
      <p></p>
      <h3></h3>
      <p></p>
      <h3></h3>
      <p></p>
      <img width={400} className="monimage" src="/jit-logo.jpg"></img>
    </div>
  );
}

export default OnestQui;
