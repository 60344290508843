import React from "react";

import "./header.scss";

function Header() {
  return (
    <div className="Header">
      <img className="Banner" src="/banner.png"></img>
    </div>
  );
}

export default Header;
