import React from "react";
import Annonce from "../../components/annonce/annonce";

import "./annonce.scss";

interface Annonce {
  img: string;
  title: string;
  description: string;
  url: string;
  price: number;
}
const annonces: Annonce[] = [
  {
    img: "/moto.jpg",
    title: "Motorcycle",
    description: "hs nozzle but working nice",
    url: "",
    price: 3500,
  },
  {
    img: "/mini.png",
    title: "My dog",
    description: "Minnie my dog makes too much noise i want to sell her",
    url: "",
    price: 100,
  },
  {
    img: "/ra.png",
    title: "Radorable",
    description: "",
    url: "",
    price: 100,
  },
  {
    img: "/1000.webp",
    title: "Sandblasting cabinet",
    description:
      "EXCLUSIVE MODEL ON THE MARKET Sand blast cabin, on legs, large front door, much more space than identical model with side door. Complete equipment included: gloves, neon light, gun and nozzles. Net work area: 22 wide x 34 front x 21",
    url: "",
    price: 230,
  },
  {
    img: "/raccoon.png",
    title: "Talifern",
    description: "Talifern derivative NFT",
    url: "",
    price: 20,
  },
  {
    img: "/brouette.jpg",
    title: "wheelbarrow",
    description: "stolen from the city's technical department ",
    url: "",
    price: 95,
  },

  {
    img: "/1006.webp",
    title: "Ultra HD TV",
    description: "TV used to play video game with a RTX 4070",
    url: "",
    price: 230,
  },
  {
    img: "/beer.jpeg",
    title: "The rest of the Dan beer",
    description: "Dan is too drunk to finish it",
    url: "",
    price: 140,
  },
  {
    img: "/lunette.jpg",
    title: "eyeglass case",
    description: "New Dolce & Gabana",
    url: "",
    price: 83,
  },
  {
    img: "/1019.webp",
    title: "Frame hooks",
    description:
      "FIRST-QUALITY picture frame hooks Hooks approved for works of art of residential commercial value, steel quality that won't bend or tear the wall, your picture frames will never fall off, gold-plated nails are very fine concrete.",
    url: "",
    price: 22,
  },

  {
    img: "/cc2.jpg",
    title: "camping car",
    description:
      "runs perfectly but does not start, for handyman.... otherwise very good condition the puppy are repaired but not much.",
    url: "",
    price: 14500,
  },
  {
    img: "/revu.jpg",
    title: "bodybuilding magazine",
    description: "each",
    url: "",
    price: 10,
  },

  {
    img: "/tatoo.jpg",
    title: "Get a flash tatoo",
    description: "",
    url: "",
    price: 30,
  },
  {
    img: "/debardeur.jpg",
    title: "my tshirt",
    description:
      "I'm selling because it's too tight for me now... possibility to wash it before selling",
    url: "",
    price: 45,
  },
  {
    img: "/stable.png",
    title: "The radix stable coin reserve account",
    description:
      "i have the private key to the radix stable coin reserve account i will sell it for 2000 $JIT",
    url: "",
    price: 2000,
  },
  {
    img: "/passoire.jpg",
    title: "strainer",
    description: "i dont like pasta",
    url: "",
    price: 10,
  },

  {
    img: "/webcam.webp",
    title: "small, discreet camera",
    description:
      "small discreet camera ideal for home surveillance or checkroom or cubbyhole.... Im selling because the quality is not very good",
    url: "",
    price: 35,
  },
  {
    img: "/tuile.webp",
    title: "13th century porcelain tile",
    description:
      "Very good condition. 24 cm x 12 cm. With wall brackets. Nice above a couple's bed, or to identify the boys' room vs. the girls', etc.",
    url: "",
    price: 197,
  },

  {
    img: "/marketing.jpg",
    title: "Marketing for dummies book",
    description: "I'm adam i'm selling it because i don't need it anymore",
    url: "",
    price: 30,
  },
  {
    img: "/1008.jpg",
    title: "moutarde de dijon",
    description:
      "Falling off the truck, 10$JIT per 800ml jar 200 $JIT for 10 jars",
    url: "",
    price: 10,
  },

  {
    img: "/1017.webp",
    title: "Kitchen sink",
    description:
      "double kitchen sink with tap and vegetable hose, it's not elon musk's but it's worth the money",
    url: "",
    price: 65,
  },
];

function Annonces() {
  return (
    <div className="annonce">
      <h1>
        The first and only onchain fully decentralized, scallable and sharded
        marketplace on Radix
      </h1>
      <h3>Buy and sell items directly from the community members in $JIT</h3>
      {annonces.map((an) => {
        return (
          <Annonce
            img={an.img}
            title={an.title}
            description={an.description}
            url={an.url}
            price={an.price}
          />
        );
      })}
    </div>
  );
}

export default Annonces;
