import React, { useEffect, useState } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Menu from "./components/menu/menu";
import GetFLash from "./components/pubs/getFlash/getFLash";

import Accueil from "./pages/accueil/accueil";
import Inscription from "./pages/inscription/inscription";
import OnestQui from "./pages/onestqui/onestqui";
import Programme from "./pages/programme/programme";

import PubLeft from "./components/pubs/pubLeft/pubLeft";
import Partenaire from "./pages/partenaire/partenaire";
import Annonces from "./pages/annonce/annonce";
import Idee from "./pages/idee/idee";
import Blog from "./pages/blog/blog";
import Danlock from "./pages/blog/danlock/blog";

function App() {
  return (
    <div className="App">
      <GetFLash />

      <div className="Content" style={{ minHeight: "88vh" }}>
        <Header />
        {/* 
// @ts-ignore */}
        <radix-connect-button />
        <div
          className="Column"
          style={{
            background: "black",
            padding: "10px",
            alignItems: "center",
            justifyContent: "center",
            gap: "60px",
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: "blue", font: "bold", fontSize: "22px" }}
            href="https://ociswap.com/resource_rdx1tklqwvhfsm968vpnk03nn9g7kqp28h5du4x7mxnhjtqvq5jy97864s"
          >
            Buy $JIT
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            style={{ color: "blue", font: "bold", fontSize: "22px" }}
            href="https://t.me/justintimeradix"
          >
            Join the telegram
          </a>
        </div>
        <div className="Column column-mobile">
          <BrowserRouter>
            <Menu />
            <div className="Page">
              <Routes>
                <Route path="/" element={<Accueil />} />
                <Route path="/whoweare.html" element={<OnestQui />} />
                <Route path="/airdrops.html" element={<Inscription />} />

                <Route path="/roadmap.html" element={<Programme />} />

                <Route path="/investors.html" element={<Partenaire />} />
                <Route path="/marketplace.html" element={<Annonces />} />
                <Route path="/ideas.html" element={<Idee />} />
                <Route path="/blog.html" element={<Blog />} />
                <Route path="/blog/danlock.html" element={<Danlock />} />
              </Routes>
              <Outlet />
            </div>
          </BrowserRouter>
          <PubLeft />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
