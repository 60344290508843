import React from "react";
import PubContainer from "../pubContainer";

import "./pussyFinder.scss";

function PussyFinder() {
  return (
    <>
      <PubContainer>
        <div className="PussyFinder">
          <img className="fuck" src="/jit.jpg"></img>
        </div>
      </PubContainer>
      <PubContainer>
        <div className="" style={{ marginTop: "3rem", width: "100px" }}>
          <img
            style={{ marginTop: "3rem", width: "300px" }}
            className="fuck"
            src="/late.jpg"
          ></img>
        </div>
      </PubContainer>
    </>
  );
}

export default PussyFinder;
