import React from "react";

import "./accueil.scss";

function Accueil() {
  return (
    <div className="Accueil">
      <h1> You're just in time, but not for long</h1>
      <p>why be $EARLY or $LATE if you can be $JIT ?</p>
      <p>
        because real men don't arrive early or late, you arrived at the right
        place at the right time !!<br></br>{" "}
        <div
          style={{
            padding: "5px",
            marginTop: "10px",
            backgroundImage: 'url("assets/en.png")',
          }}
        ></div>
      </p>
      <img src="telegram.png" style={{ width: "300px" }} />
      <p>what if Dan had been born too early??</p>
      <p>
        he'd probably have become a knight and wouldn't have been able to
        develop Radix
      </p>
      <img src="cheval.png" style={{ width: "300px" }} />
      <p>what if Piers had been born too late??</p>
      <p>he would probably live on mars with his robot wife</p>
      <img src="aaa.png" style={{ width: "300px" }} />\
      <p>
        but fortunately they came into the world just in time to deliver the
        best of all projects.{" "}
      </p>
      <img src="perf.jpeg" style={{ width: "400px" }} />
      <p>
        So dont be the too early Dan or the too late Piers, be just in time and
        early buy $JIT before to be late
      </p>
      <iframe
        src="https://www.caviarnine.com/embed/trade?style=c9_light01&buy_resource=resource_rdx1tklqwvhfsm968vpnk03nn9g7kqp28h5du4x7mxnhjtqvq5jy97864s"
        width="467px"
        height="544px"
        className="mobile-hide"
        frameBorder="0"
      />
    </div>
  );
}

export default Accueil;
